import { Table, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { StarFilled } from "@ant-design/icons";
import useFetch from "../../hooks/fetch";
import { Link } from "react-router-dom";

export default function ManageFeedback() {
  const _fetch = useFetch();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [feedbacks, setFeedbacks] = useState([]);

  async function init() {
    try {
      const res = await _fetch("feedback");
      if (res.success) {
        setFeedbacks(res.response);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch feedbacks");
      setState("error");
    }
  }

  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (text) => (
        <Link to={`/clients?_id=${text?._id}`} target="_blank" rel="noreferrer">
          <p>{text?.name}</p>
          <p>{text?.gst_number}</p>
        </Link>
      ),
    },
    {
      title: "Client Order",
      dataIndex: "order",
      key: "order",
      render: (text) => (
        <Link to={`/orders?_id=${text?._id}`} target="_blank" rel="noreferrer">
          <p>{text?.workflow_number}</p>
          <p>{text?.consignee_address}</p>
        </Link>
      ),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (text) => (
        <Typography.Text>
          {text}{" "}
          <StarFilled
            style={{
              color: "gold",
            }}
          />
        </Typography.Text>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
  ];

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="flex-1 w-full my-20">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          View Feedback
        </Typography.Title>
      </div>
      <div className="mt-4">
        <Table
          columns={columns}
          dataSource={feedbacks}
          loading={state === "loading"}
          rowKey="_id"
          pagination={{
            pageSize: 10,
          }}
          bordered
          scroll={{
            x: true,
          }}
        />
      </div>
    </div>
  );
}
