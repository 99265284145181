import React from 'react';
import { Layout } from 'antd';
import { FaWhatsapp } from 'react-icons/fa';

const { Footer: AntdFooter } = Layout;

export default function Footer() {
  return (
    <AntdFooter className="w-full bg-gradient-to-r from-purple-600 via-indigo-500 to-blue-600 text-white py-16">
      <div className="container mx-auto px-6 md:px-20 flex flex-col lg:flex-row justify-between gap-12">
        {/* Company Info Section */}
        <div className="flex flex-col space-y-4">
          <h3 className="text-3xl font-semibold">Rashi Cables</h3>
          <p className="opacity-80 text-lg">
            We are a leading manufacturer of high-quality cables, providing durable, safe, and reliable wire solutions.
          </p>
          <p className="text-lg flex items-center gap-2">
            <a href="https://wa.me/+919251061056" target="_blank" rel="noopener noreferrer" className="text-white hover:text-yellow-300">
              <FaWhatsapp size={20} />
            </a>
            <span>+91 9251061056</span>
          </p>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-white mt-8 pt-4 text-center">
        <p className="text-sm opacity-70">
          Rashi Cables ©{new Date().getFullYear()} - All Rights Reserved.
        </p>
        <p className="text-sm text-white">
          Made with ❤️ by <a href="https://www.linkedin.com/in/dakkkshh/" target="_blank" rel="noreferrer" className="text-yellow-300">dakkkshh</a>
        </p>
      </div>
    </AntdFooter>
  );
}
