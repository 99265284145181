import { Button, Layout, message } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MenuOutlined, CloseOutlined, LogoutOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useUser } from "../../hooks/user";
import { logout as logoutAction } from "../../redux/userSlice";
import { getMenuRoutes } from "../../services/access-control";
import { useConst } from "../../hooks/const";
import logo from "../../assets/images/logo.png";
import useFetch from "../../hooks/fetch";

const { Header: AntdHeader } = Layout;

export default function Header() {
  const user = useUser();
  const consts = useConst();
  const _fetch = useFetch();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [routes, setRoutes] = useState(getMenuRoutes(user, consts));
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // Track scroll state

  let menuItems = routes.map((route, index) => ({
    key: `nav-menu-${index}`,
    title: route.title,
    label: (
      <Link to={route.path} className="text-inherit m-0">
        {route.title}
      </Link>
    ),
  }));

  if (!user || !user._id) {
    menuItems.push({
      key: "nav-menu-auction-login",
      title: "Auction Login",
      label: (
        <a
          className="text-inherit m-0"
          href={process.env.REACT_APP_AUCTION_HOME_URL}
        >
          Auction Login
        </a>
      ),
    });
  }

  const selectedKey = menuItems.find((item) => {
    return window.location.pathname === item.label.props.to;
  })?.key;

  async function handleLogout() {
    try {
      setLoading(true);
      const res = await _fetch("user/logout", {
        method: "POST",
      });
      if (res.success) {
        message.success(res.message);
        dispatch(logoutAction());
        navigate("/");
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong while logging out");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setRoutes(getMenuRoutes(user, consts));

    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [user, consts]);

  return (
    <AntdHeader
      className={`fixed top-0 left-0 w-full z-50  transition-all duration-300 ease-in-out h-20`}
      style={{
        backgroundColor: isScrolled ? "white" : "transparent",
        boxShadow: isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <div className=" w-full h-full flex items-stretch justify-between">
        <div className="flex items-center py-3 flex-1">
          <div className="flex items-center justify-center w-full md:w-auto">
            <Link to="/" className="flex items-center justify-center">
              <img src={logo} alt="Rashi Cables" className="h-12 object-contain" />
            </Link>
          </div>
        </div>

        <div className="hidden md:flex items-center justify-items-end gap-x-4 lg:gap-x-8">
          {menuItems.map((item) => (
            <div
              key={item.key}
              className="text-base h-full flex items-center transition-all"
              style={{
                borderBottom:
                  selectedKey === item.key ? "2px solid #1890ff" : "none",
                color: selectedKey === item.key ? "#1890ff" : "inherit",
                fontWeight: selectedKey === item.key ? "500" : "400",
              }}
            >
              {item.label}
            </div>
          ))}
          {user?._id && (
            <Button
              title="Logout"
              type="text"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              loading={loading}
              disabled={loading}
            />
          )}
        </div>

        <div className="flex items-center">
          <Button
            type="primary"
            className="md:hidden rounded"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
            icon={menuOpen ? <CloseOutlined /> : <MenuOutlined />}
            title="Toggle Menu"
          />
        </div>
      </div>

      {menuOpen && (
        <div className="w-screen bg-white md:hidden w-full flex flex-col items-center gap-y-2 pb-4">
          {menuItems.map((item) => (
            <div
              key={item.key}
              className="text-base h-full flex items-center transition-all w-full rounded px-2 py-2"
              style={{
                color: selectedKey === item.key ? "#1890ff" : "inherit",
                fontWeight: selectedKey === item.key ? "500" : "400",
                backgroundColor:
                  selectedKey === item.key ? "#f0f0f0" : "inherit",
              }}
              onClick={() => {
                setMenuOpen(false);
              }}
            >
              {item.label}
            </div>
          ))}
          {user?._id && (
            <Button
              title="Logout"
              type="text"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              loading={loading}
              disabled={loading}
            >
              Logout
            </Button>
          )}
        </div>
      )}
    </AntdHeader>
  );
}
