import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  PlusOutlined,
  SearchOutlined,
  MinusCircleOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/fetch";
import { useConst } from "../../hooks/const";

export default function Vendors() {
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id") || "";
  const _fetch = useFetch();
  const consts = useConst();
  const navigate = useNavigate();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [vendors, setVendors] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    gst_number: "",
    emails: [],
  });
  const [selectedVendor, setSelectedVendor] = useState({});
  const [addVendorModalVisible, setAddVendorModalVisible] = useState(false);

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    if (dataIndex === "emails") {
      setFilters((prev) => ({
        ...prev,
        emails: selectedKeys,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [dataIndex]: selectedKeys[0],
      }));
    }
  }

  function handleReset(clearFilters) {
    clearFilters();
    setFilters((prev) => ({
      ...prev,
      name: "",
      gst_number: "",
      emails: [],
    }));
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "GST Number",
      dataIndex: "gst_number",
      key: "gst_number",
      ...getColumnSearchProps("gst_number"),
    },
    {
      title: "GST Address",
      dataIndex: "gst_address",
      key: "gst_address",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: Object.keys(consts?.vendor_types).map((key) => ({
        text: consts?.vendor_types[key],
        value: consts?.vendor_types[key],
      })),
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Emails",
      dataIndex: "emails",
      key: "emails",
      render: (emails) => (emails?.length > 0 ? emails.join(", ") : "N/A"),
      ...getColumnSearchProps("emails"),
    },
    {
      title: "Phones",
      dataIndex: "phones",
      key: "phones",
      render: (phones) => (phones?.length > 0 ? phones.join(", ") : "N/A"),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   filters: Object.keys(consts?.vendor_statuses).map((key) => ({
    //     text: consts?.vendor_statuses[key],
    //     value: consts?.vendor_statuses[key],
    //   })),
    //   onFilter: (value, record) => record.status === value,
    // },
    {
      title: "Created By",
      dataIndex: "director",
      key: "director",
      render: (director) => {
        if (director) {
          return <ul>
            <li>Name: {director?.name}</li>
            <li>Email: {director?.email}</li>
          </ul>
        }

      },
    },
    {
      title: "Bank Details",
      dataIndex: "bank_details",
      key: "bank_details",
      render: (bank_details) => (
        <ul>
          <li>Bank Name: {bank_details?.bank_name}</li>
          <li>Account Name: {bank_details?.account_holder_name}</li>
          <li>Account Number: {bank_details?.account_number}</li>
          <li>IFSC Code: {bank_details?.ifsc_code}</li>
          <li>Branch: {bank_details?.branch_name}</li>
        </ul>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex flex-col gap-5 items-center">
             <div className="flex gap-x-2 items-center">
          <Button
            title="Edit "
            type="primary"
            onClick={() => {
              setSelectedVendor(record);
            }}
            icon={<EditOutlined />}
          />
          
          {record?.status === consts?.vendor_statuses?.REQUESTED && (
            <Button
              title="Approve Vendor"
              type="primary"
              onClick={() => {
                handleApproveVendor(record?._id);
              }}
              icon={<CheckCircleOutlined />}
            />
          )}
        </div>
          <Button
            title="View Orders" 
            type="link"
            onClick={() => {
              navigate(`/vendor/orders/${record._id}`);
            }}
          >View Po</Button>
        </div>
     
      ),
    },
  ];

  async function init() {
    try {
      setState("loading");
      let url = "vendor";
      let queryAdded = false;
      if (filters.name) {
        url += `?name=${filters.name}`;
        queryAdded = true;
      }
      if (filters.gst_number) {
        url += `${queryAdded ? "&" : "?"}gst_number=${filters.gst_number}`;
        queryAdded = true;
      }
      if (filters.emails?.length > 0) {
        url += `${queryAdded ? "&" : "?"}emails=${JSON.stringify(
          filters.emails
        )}`;
      }
      const res = await _fetch(url);
      if (res.success) {
        setState("success");
        if (_id !== "") {
          setVendors(res.response.filter((vendor) => vendor._id === _id));
        } else setVendors(res.response);
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      message.error("Something went wrong while fetching clients");
      setState("error");
    }
  }

  async function handleAddVendor(val) {
    try {
      setState("loading");
      const res = await _fetch("vendor", {
        method: "POST",
        body: val,
      });
      if (res.success) {
        message.success(res.message);
        setAddVendorModalVisible(false);
        setVendors((prev) => [res.response, ...prev]);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      console.error(err);
      message.error("Something went wrong while adding vendor");
      setState("error");
    }
  }

  async function handleUpdateVendor(val) {
    try {
      setState("loading");
      const difference = Object.keys(val).reduce((acc, key) => {
        if (Array.isArray(val[key])) {
          if (val[key].join(",") !== selectedVendor[key].join(",")) {
            acc[key] = val[key];
          }
          return acc;
        } else if (val[key] instanceof Object) {
          const jsonWithoutExtraFields = selectedVendor[key];
          delete jsonWithoutExtraFields._id;
          delete jsonWithoutExtraFields.__v;
          delete jsonWithoutExtraFields.createdAt;
          delete jsonWithoutExtraFields.updatedAt;
          delete jsonWithoutExtraFields.createdBy;
          delete jsonWithoutExtraFields.updatedBy;
          if (
            JSON.stringify(val[key]) !== JSON.stringify(jsonWithoutExtraFields)
          ) {
            acc[key] = val[key];
          }
          return acc;
        } else if (val[key] !== selectedVendor[key]) {
          acc[key] = val[key];
        }
        return acc;
      }, {});
      const res = await _fetch(`vendor/${selectedVendor?._id}`, {
        method: "PATCH",
        body: difference,
      });
      if (res.success) {
        message.success(res.message);
        setAddVendorModalVisible(false);
        setSelectedVendor({});
        setVendors((prev) => {
          const index = prev.findIndex((v) => v._id === res.response._id);
          prev[index] = {
            ...prev[index],
            ...difference,
          };
          return [...prev];
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      console.error(err);
      message.error("Something went wrong while updating vendor");
      setState("error");
    }
  }

  async function handleApproveVendor(id) {
    try {
      setState("loading");
      const res = await _fetch(`vendor/${id}`, {
        method: "PATCH",
        body: {
          status: consts?.vendor_statuses?.APPROVED,
        },
      });
      if (res.success) {
        message.success(res.message);
        setVendors((prev) => {
          const index = prev.findIndex((v) => v._id === res.response?._id);
          prev[index] = {
            ...prev[index],
            status: res.response?.status,
          };
          return [...prev];
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      console.error(err);
      message.error("Something went wrong while approving vendor");
      setState("error");
    }
  }

  useEffect(() => {
    init();
  }, [filters, _id]);

  return (
    <div className="flex-1 w-full mt-20">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Manage Vendors
        </Typography.Title>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          loading={state === "loading"}
          onClick={() => setAddVendorModalVisible(true)}
        >
          Add Vendor
        </Button>
      </div>
      <div className="mt-4">
        <Table
          columns={columns}
          dataSource={vendors}
          loading={state === "loading"}
          rowKey="_id"
          pagination={{
            pageSize: 10,
          }}
          bordered
          scroll={{
            x: true,
          }}
        />
      </div>
      <Modal
        title={addVendorModalVisible ? "Add Vendor" : "Edit Vendor"}
        open={addVendorModalVisible || selectedVendor?._id}
        onCancel={() => {
          setAddVendorModalVisible(false);
          setSelectedVendor({});
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          name="create-vendor"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            addVendorModalVisible
              ? {
                status: consts?.vendor_statuses?.REQUESTED,
                type: consts?.vendor_types?.MANUFACTURER,
              }
              : selectedVendor
          }
          onFinish={
            addVendorModalVisible ? handleAddVendor : handleUpdateVendor
          }
          className="md:min-w-[400px]"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="GST Number"
            name="gst_number"
            rules={[
              {
                required: true,
                message: "Please input your GST number!",
              },
            ]}
          >
            <Input placeholder="GST Number" />
          </Form.Item>
          <Form.Item
            label="GST Address"
            name="gst_address"
            rules={[
              {
                required: true,
                message: "Please input your GST address!",
              },
            ]}
          >
            <Input placeholder="GST Address" />
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Select placeholder="Select Type">
              {Object.keys(consts?.vendor_types).map((key) => (
                <Select.Option key={key} value={consts?.vendor_types[key]}>
                  {consts?.vendor_types[key]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select status!",
              },
            ]}
          >
            <Select placeholder="Select Status">
              {Object.keys(consts?.vendor_statuses).map((key) => (
                <Select.Option key={key} value={consts?.vendor_statuses[key]}>
                  {consts?.vendor_statuses[key]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.List
            name="emails"
            rules={[
              {
                validator: async (_, emails) => {
                  if (!emails || emails.length < 1) {
                    message.error("Please add at least one email");
                    return Promise.reject("Please add at least one email");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="flex items-baseline gap-x-4" key={key}>
                    <Form.Item
                      {...restField}
                      label="Email"
                      name={[name]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                      className="flex-1"
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.List
            name="phones"
            rules={[
              {
                validator: async (_, phones) => {
                  if (!phones || phones.length < 1) {
                    message.error("Please add at least one phone number");
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="flex items-baseline gap-x-4" key={key}>
                    <Form.Item
                      {...restField}
                      label="Phone Number"
                      name={[name]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                      className="flex-1"
                    >
                      <Input placeholder="Phone Number" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Phone Number
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            name={["bank_details", "account_number"]}
            label="Account Number"
            rules={[
              {
                required: true,
                message: "Please input your account number!",
              },
            ]}
          >
            <Input placeholder="Account Number" />
          </Form.Item>
          <Form.Item
            name={["bank_details", "ifsc_code"]}
            label="IFSC"
            rules={[
              {
                required: true,
                message: "Please input your IFSC!",
              },
            ]}
          >
            <Input placeholder="IFSC" />
          </Form.Item>
          <Form.Item
            name={["bank_details", "bank_name"]}
            label="Bank Name"
            rules={[
              {
                required: true,
                message: "Please input your bank na,e!",
              },
            ]}
          >
            <Input placeholder="Bank Name" />
          </Form.Item>
          <Form.Item
            name={["bank_details", "branch_name"]}
            label="Branch Name"
            rules={[
              {
                required: true,
                message: "Please input your branch name!",
              },
            ]}
          >
            <Input placeholder="Branch Name" />
          </Form.Item>
          <Form.Item
            name={["bank_details", "account_holder_name"]}
            label="Account Holder Name"
            rules={[
              {
                required: true,
                message: "Please input your account holder name!",
              },
            ]}
          >
            <Input placeholder="Account Holder Name" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full"
            >
              {addVendorModalVisible ? "Add Vendor" : "Update Vendor"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
