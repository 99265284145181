import {
    Typography,
} from 'antd'
import HomeBGImage from '../../assets/images/home-bg.jpeg'
import { Link } from 'react-router-dom'
import ProductSection from './Product'
import AboutSection from './About'
import ClientsSection from './Client'
import HeroSlider from './Slider'
import Product2 from './product2'

export default function PublicUser() {
    return (
        <div className='w-screen'>
            <HeroSlider />
            {/* <ProductSection /> */}
            <Product2/>
            <ClientsSection />
            <AboutSection />
        </div>
    )
}