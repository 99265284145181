import { Button, DatePicker, Form, Input, InputNumber, Modal, Table, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
    useNavigate
} from "react-router-dom";

import useFetch from "../../hooks/fetch";
import {
    useConst
} from "../../hooks/const";
import moment from "moment";

export default function Auctions(){
    const navigate = useNavigate();
    const _fetch = useFetch();
    const consts = useConst();
    const [state, setState] = useState("loading"); // ["success", "loading", "error"] 
    const [auctions, setAuctions] = useState([]);
    const [addAuctionModalVisible, setAddAuctionModalVisible] = useState(false);

    const columns = [
        {
            title: "Auction ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Auction Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Auction Description",
            dataIndex: "description",
            key: "description",
            ellipsis: true
        },
        {
            title: "Item Details",
            dataIndex: "item",
            key: "item",
            render: (item) => (
                <div>
                    <Typography.Text>
                        {item.name}
                    </Typography.Text>
                    <br />
                    <Typography.Text type="secondary">
                        {item.description}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        {item.quantity} {item.unit}
                    </Typography.Text>
                    <br />
                </div>
            )
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            render: (startDate) => (
                moment(startDate).format("DD MMM YYYY hh:mm A")
            ),
            sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            render: (endDate) => (
                moment(endDate).format("DD MMM YYYY hh:mm A")
            ),
            sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix()
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: Object.values(consts?.auction_status).map(status => ({
                text: status,
                value: status
            })),
            onFilter: (value, record) => record.status === value
        },
        {
            title: "User Count",
            dataIndex: "users",
            key: "users",
            render: (users) => users.length
        },
        {
            title: "Bid Count",
            dataIndex: "bidCount",
            key: "bidCount",
            sorter: (a, b) => a.bidCount - b.bidCount
        }
    ];
    
    async function init(){
        try {
            setState("loading");
            const res = await _fetch("auction");
            if (res.success){
                setAuctions(res.response);
                setState("success");
            } else {
                message.error(res.message);
                setState("error");
            }
        } catch (err) {
            message.error("Failed to fetch auctions");
            setState("error");
        }
    }

    async function handleAddAuction(values){
        try {
            setState("loading");
            const res = await _fetch("auction", {
                method: "POST",
                body: values
            });
            if (res.success){
                message.success(res.message);
                setAuctions((prev) => [
                    ...prev,
                    res.response
                ]);
                setAddAuctionModalVisible(false);
                setState("success");
            } else {
                message.error(res.message);
                setState("error");
            }
        } catch (err) {
            message.error("Failed to add auction");
            setState("error");
        }
    }
    
    useEffect(() => {
        init();
    }, []);

    return (
        <div className="flex-1 w-full my-20">
            <div
                className="flex justify-between items-center"
            >
                <Typography.Title
                    level={2}
                    style={{
                        margin: 0
                    }}
                >
                    Manage Auctions
                </Typography.Title>
                <Button
                    type="dashed"
                    icon={
                        <PlusOutlined />
                    }
                    loading={state === "loading"}
                    onClick={() => setAddAuctionModalVisible(true)}
                >
                    Add Auction
                </Button>
            </div>
            <div className="mt-4">
            <Table
                columns={columns}
                dataSource={auctions}
                loading={state === "loading"}
                rowKey="_id"
                pagination={{
                    pageSize: 10,
                }}
                bordered
                scroll={{
                    x: true,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate(`/auctions/${record._id}`)
                        }
                    }
                }}
            />
            </div>
            <Modal
                title="Add Auction"
                open={addAuctionModalVisible}
                onCancel={() => setAddAuctionModalVisible(false)}
                footer={null}
                destroyOnClose
            >
                <Form
                    name="add-auction"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={handleAddAuction}
                >
                    <Form.Item
                        label="Auction Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input auction name"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Auction Name"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Auction Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: "Please input auction description"
                            }
                        ]}
                    >
                        <Input.TextArea
                            placeholder="Auction Description"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Item Name"
                        name={["item", "name"]}
                        rules={[
                            {
                                required: true,
                                message: "Please input item name"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Item Name"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Item Description"
                        name={["item", "description"]}
                        rules={[
                            {
                                required: true,
                                message: "Please input item description"
                            }
                        ]}
                    >
                        <Input.TextArea
                            placeholder="Item Description"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Item Quantity"
                        name={["item", "quantity"]}
                        rules={[
                            {
                                required: true,
                                message: "Please input item quantity"
                            }
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            min={1}
                            placeholder="Item Quantity"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Item Unit"
                        name={["item", "unit"]}
                        rules={[
                            {
                                required: true,
                                message: "Please input item unit"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Item Unit"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: "Please input start date"
                            }
                        ]}
                    >
                        <DatePicker
                            showTime
                            format="DD MMM YYYY hh:mm:ss A"
                            className="w-full"
                            placeholder="Start Date"
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="endDate"
                        rules={[
                            {
                                required: true,
                                message: "Please input end date"
                            }
                        ]}
                    >
                        <DatePicker
                            showTime
                            format="DD MMM YYYY hh:mm:ss A"
                            className="w-full"
                            placeholder="End Date"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Minimum Bid"
                        name="minimumBid"
                        rules={[
                            {
                                required: true,
                                message: "Please input minimum bid"
                            }
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            min={1}
                            placeholder="Minimum Bid"
                            suffix="INR"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state === "loading"}
                            className="w-full"
                        >
                            Add Auction
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>   
    )
}