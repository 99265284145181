import { useEffect, useState } from "react";
import useFetch from "../../hooks/fetch";
import { useParams } from "react-router-dom";
import { Form, InputNumber, message, Select, Input, Button, Spin } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const DispatchPo = () => {
  const { id } = useParams();
  const _fetch = useFetch();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [po, setPO] = useState([]);
  const [units, setUnits] = useState({});

  async function init() {
    try {
      setState("loading");
      const res = await _fetch(`purchase-order/dispatch_po/${id}`);
      if (res.success) {
        setPO(res.response.PurchaseOrder);
        setUnits(res?.response?.units);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      message.error("Failed to fetch purchase order details.");
      setState("error");
    }
  }

  // Form submission
  async function submitForm(values) {
    const items = values?.items?.map((item) => {
      const {_id,...rest} = item;
      return {
       ...rest
      };
    })
    const payload = {
      items
    }
    try {
      const res = await _fetch(`purchase-order/dispatch_po/${id}`, {
        method: "PATCH",
        body: payload,
      });
      if (res.success) {
        message.success(res.message);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      message.error("Failed to update purchase order.");
      setState("error");
    }
  }

  useEffect(() => {
    if (id) init();
  }, [id]);


  if (state === "loading") {
    return (
      <div className='flex flex-col items-center justify-center flex-1 w-full'>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h1 className="text-3xl font-semibold mb-4 mt-4">Update Items for Dispatch</h1>
      </div>
      <Form
        name={"Update Dispatch PO"}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{

          items: po?.items || [],
        }}
        onFinish={submitForm}
        className="md:min-w-[400px]"
      >
        <Form.List
          label="Items"
          name="items"
          rules={[
            {
              validator: async (_, items) => {
                if (!items || items.length < 1) {
                  message.error("Please add at least one item");
                  return Promise.reject("Please add at least one item");
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className="">
                  <div className="flex items-baseline gap-x-4">
                    {/* Item Name */}
                    <Form.Item
                      {...restField}
                      label="Item Name"
                      name={[name, "name"]}
                      rules={[{ required: true, message: "Missing item name" }]}
                      className="w-1/2"
                    >
                      <Input placeholder="Item name" />
                    </Form.Item>

                    {/* Item Price */}
                    <Form.Item
                      {...restField}
                      label="Price"
                      name={[name, "price"]}
                      rules={[{ required: true, message: "Missing item price" }]}
                      className="w-1/2"
                    >
                      <InputNumber
                        min={1}
                        placeholder="Price"
                        className="w-full"
                      />
                    </Form.Item>
                  </div>
                  <div className="flex items-center gap-x-4">
                    {/* Item Quantity */}
                    <Form.Item
                      {...restField}
                      label="Quantity"
                      name={[name, "quantity"]}
                      rules={[{ required: true, message: "Missing item quantity" }]}
                      className="w-full"
                    >
                      <InputNumber
                        min={1}
                        placeholder="Quantity"
                        className="w-3/4"
                      />
                    </Form.Item>

                    {/* Item Unit */}
                    <Form.Item
                      {...restField}
                      label="Unit"
                      name={[name, "unit"]}
                      rules={[{ required: true, message: "Missing item unit" }]}
                      className="w-1/4"
                    >
                      <Select
                        showSearch
                        placeholder="Choose a unit"
                        className="w-full"
                      >
                        {Object.values(units).map((unit) => (
                          <Select.Option value={unit} key={unit}>
                            {unit}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {/* Remove Item */}
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* Submit Button */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={state === "loading"}
            className="w-full"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DispatchPo;
