import React from 'react';
import aboutImage from '../../assets/images/cabel.jpg'; // Replace with your image path
import missionImage from '../../assets/images/cabel_bundle.jpg'; // Replace with your image path

const AboutSection = () => {
  return (
    <section className="bg-gray-50 py-20 px-6 md:px-20">
      <div className="max-w-7xl mx-auto text-center">
        {/* Title and Introduction */}
        <h2 className="text-4xl font-bold text-gray-800 mb-4">
          About Us
        </h2>
        <p className="text-lg text-gray-600 mb-12">
          We are a trusted leader in cable manufacturing, providing high-quality products and exceptional service. Our expertise spans over 30 years, offering solutions for every need.
        </p>

        {/* Mission and Vision Section */}
        <div className="flex flex-col lg:flex-row justify-between items-center gap-16">
          {/* Text Section */}
          <div className="flex-1">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">
              Our Mission
            </h3>
            <p className="text-lg text-gray-600 mb-6">
              At [Company Name], we are committed to delivering reliable, durable, and safe cable solutions that meet the evolving needs of our customers. Through modern technology and cutting-edge designs, we strive to make a global impact.
            </p>
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">
              Our Vision
            </h3>
            <p className="text-lg text-gray-600">
              To be the most trusted name in cable manufacturing, driving innovation and excellence in connectivity solutions for all industries.
            </p>
          </div>

          {/* Image Section */}
          <div className="flex-1 relative rounded-lg shadow-lg overflow-hidden">
            <img
              src={aboutImage}
              alt="About Us"
              className="w-full h-full object-cover rounded-lg transform transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
            />
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 flex justify-center items-center">
              <p className="text-white font-bold text-xl sm:text-2xl md:text-3xl">
                Delivering Excellence for Over 30 Years
              </p>
            </div>
          </div>
        </div>

        {/* Values Section */}
        <div className="mt-16 bg-indigo-600 py-10 px-6 text-white rounded-lg">
          <h3 className="text-3xl font-semibold text-center mb-6">
            Our Core Values
          </h3>
          <div className="flex flex-wrap justify-center gap-8 text-center">
            <div className="max-w-xs">
              <div className="mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-12 w-12 mx-auto text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c1.38 0 2.5-.62 3.22-1.6m0 3.2C14.5 10.62 13.38 10 12 10c-1.38 0-2.5.62-3.22 1.6m0-3.2C9.5 10.62 10.62 12 12 12c1.38 0 2.5-.62 3.22-1.6" />
                </svg>
              </div>
              <h4 className="text-xl font-bold mb-2">Innovation</h4>
              <p>We embrace new ideas and continuously innovate to provide the best solutions to our customers.</p>
            </div>
            <div className="max-w-xs">
              <div className="mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-12 w-12 mx-auto text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 5h7m0 0l-3 3m3-3l-3-3M4 19v2c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-2M4 19h16" />
                </svg>
              </div>
              <h4 className="text-xl font-bold mb-2">Customer Focus</h4>
              <p>Our customers are at the heart of everything we do. We deliver personalized service and build lasting relationships.</p>
            </div>
            <div className="max-w-xs">
              <div className="mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-12 w-12 mx-auto text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15l9-5-9-5-9 5 9 5z" />
                </svg>
              </div>
              <h4 className="text-xl font-bold mb-2">Quality</h4>
              <p>We are committed to providing only the highest quality cables and products that exceed industry standards.</p>
            </div>
          </div>
        </div>
        
        {/* Mission Image Section
        <div className="mt-20 flex flex-col lg:flex-row items-center gap-16">
          <div className="flex-1 relative rounded-lg shadow-lg overflow-hidden">
            <img
              src={missionImage}
              alt="Our Mission"
              className="w-full h-full object-cover rounded-lg transform transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
            />
          </div>
          <div className="flex-1">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Our Vision for the Future</h3>
            <p className="text-lg text-gray-600">
              We are dedicated to advancing our products through continuous research and development, with a focus on sustainability and reducing our environmental footprint. Our mission is to empower businesses and individuals with cutting-edge cable solutions.
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutSection;
