import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import { PlusOutlined, EditOutlined, MinusCircleOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/fetch";
import { useConst } from "../../hooks/const";
import { useUser } from "../../hooks/user";
import { getOrderActions, order_actions } from "../../services/access-control";

export default function Orders() {
  const [searchParams,] = useSearchParams();
  const _id = searchParams.get("_id") || "";
  const _fetch = useFetch();
  const consts = useConst();
  const user = useUser();
  const [addForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const actions = getOrderActions(user, consts);
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [addOrderModalVisible, setAddOrderModalVisible] = useState(false);
  const [addLocationHistory, setAddLocationHistory] = useState({});
  const [updateStatus, setUpdateStatus] = useState({});
  const [updateDeliveryStatus, setUpdateDeliveryStatus] = useState({});
  const [updatePaymentStatus, setUpdatePaymentStatus] = useState({});
  const [clients, setClients] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");

  async function getEnquiries(client_id = "") {
    try {
      setState("loading");
      let url = "enquiry";
      if (client_id) {
        url += `?client=${client_id}`;
      }
      const res = await _fetch(url);
      if (res.success) {
        setEnquiries(res.response);
        setState("success");
      } else {
        message.error(res.error);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch enquiries");
      setState("error");
    }
  }

  async function init() {
    try {
      setState("loading");
      const res = await _fetch("order");
      const res2 = await _fetch("client");
      if (res.success && res2.success) {
        if (_id !== ""){
          setOrders(res.response.filter(order => order._id === _id));
        } else {
          setOrders(res.response);
        }
        setClients(res2.response);
        await getEnquiries();
        setState("success");
      } else if (!res.success) {
        message.error(res.error);
        setState("error");
      } else {
        message.error(res2.error);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch orders");
      setState("error");
    }
  }

  function OrderAction({ record }) {
    return (
      <Space wrap>
        {actions?.includes(order_actions.UPDATE) && (
          <Button
            title="Edit Order"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedOrder(record);
              setSelectedClientId(record.client);
            }}
          />
        )}
        {actions?.includes(order_actions.UPDATE_STATUS) && (
          <Button
            title="Update Order Status"
            type="primary"
            onClick={() => {
              setUpdateStatus(record);
            }}
          >
            Update Status
          </Button>
        )}
        {actions?.includes(order_actions.LOCATION_HISTORY) && record?.status === consts.order_statuses.DISPATCHED && (
          <Button
            title="View Location History"
            type="primary"
            onClick={() => {
              setAddLocationHistory(record);
            }}
          >
            Add Location History
          </Button>
        )}
        {actions?.includes(order_actions.DELIVERY_STATUS) && record?.status === consts.order_statuses.DISPATCHED && (
          <Button
            title="Update Delivery Status"
            type="primary"
            onClick={() => {
              setUpdateDeliveryStatus(record);
            }}
          >
            Update Delivery Status
          </Button>
        )}
        {record?.delivered && (
          <Button
            title="Update Payment Status"
            type="primary"
            onClick={() => {
              setUpdatePaymentStatus(record);
            }}
          >
            Update Payment Status
          </Button>
        )}
      </Space>
    );
  }

  const allColumns = {
    workflow_number: {
      title: "Worfklow Number",
      dataIndex: "workflow_number",
      key: "workflow_number",
      render: (text, record) => (
        <div>
          <Link
            to={`/orders/${record._id}`}
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </Link>
          <br />
         {record?.offer_number &&  <p>Offer No:{record?.offer_number}</p>}
        </div>

      )
    },
    plant: {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      render: (text) => <p>{text}</p>
    },
    estimated_time: {
      title: "Estimated Date",
      dataIndex: "estimated_time",
      key: "estimated_time",
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    client: {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (text) => {
        const clientDetails = clients.find(
          (client) => client._id?.toString() === text?.toString()
        );
        return (
          <Link
            to={`/clients?_id=${clientDetails?._id}`}
            target="_blank"
            rel="noreferrer"
          >
            <p>{clientDetails?.name}</p>
            <p>{clientDetails?.gst_number}</p>
          </Link>
        );
      },
    },
    enquiry: {
      title: "Enquiry",
      dataIndex: "enquiry",
      key: "enquiry",
      render: (text) => {
        const enquiryDetails = enquiries.find(
          (enquiry) => enquiry._id?.toString() === text?.toString()
        );
        if (!enquiryDetails) return <p>No Enquiry Found</p>;
        return (
          <p>
            {enquiryDetails?.status} -{" "}
            {moment(enquiryDetails?.createdAt).format("DD-MMM-YYYY")}
          </p>
        );
      },
    },
    status: {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: Object.values(consts?.order_statuses).map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
    },
    consignee_address: {
      title: "Consignee Address",
      dataIndex: "consignee_address",
      key: "consignee_address",
    },
    items: {
      title: "Items",
      dataIndex: "items",
      key: "items",
      render: (items) => (
        <ul>
          {items.map((item) => (
            <li key={item._id}>
              <p>
                <strong>Name:</strong> {item.name}
              </p>
              <p>
                <strong>Quantity:</strong> {item.quantity} {consts?.units?.order?.quantity}
              </p>
            </li>
          ))}
        </ul>
      ),
      width: "30%",
    },
    delivered: {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
      render: (text) => (text ? "Yes" : "No"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.delivered === value,
    },
    actions: {
      title: "Actions",
      key: "actions",
      render: (text, record) => <OrderAction record={record} />,
    }
  };

  let columns = [];

  if (user.role === consts.user_roles?.ADMIN || user.role === consts.user_roles?.MARKETING_MANAGER) {
    columns = [
      allColumns.workflow_number,
      allColumns.estimated_time,
      allColumns.plant,
      allColumns.client,
      allColumns.enquiry,
      allColumns.status,
      allColumns.items,
      allColumns.consignee_address,
      allColumns.delivered,
      allColumns.actions,
    ];
  } else {
    columns = [
      allColumns.workflow_number,
      allColumns.plant,
      allColumns.estimated_time,
      allColumns.client,
      allColumns.enquiry,
      allColumns.status,
      allColumns.consignee_address,
      allColumns.delivered,
      allColumns.actions
    ];
  }

  async function createOrder(val) {
    try {
      setState("loading");
      if (!val.enquiry) {
        delete val.enquiry;
      }
      const res = await _fetch("order", {
        method: "POST",
        body: val,
      });
      if (res.success) {
        setOrders([...orders, res.response]);
        message.success("Order created successfully");
        setState("success");
        addForm.resetFields();
        setAddOrderModalVisible(false);
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      message.error("Failed to create order");
      setState("error");
    }
  }

  async function updateOrder(val) {
    try {
      setState("loading");
      const difference = Object.keys(val).reduce((acc, key) => {
        if (key === "estimated_time") {
          const isSameDate = moment(val[key]).isSame(selectedOrder[key]);
          if (!isSameDate) {
            acc[key] = val[key];
          }
        } else if (val[key] !== selectedOrder[key]) {
          acc[key] = val[key];
        }
        return acc;
      }, {});
      const res = await _fetch(`order/${selectedOrder._id}`, {
        method: "PATCH",
        body: difference,
      });
      if (res.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === selectedOrder._id) {
            return res.response;
          }
          return order;
        });
        setOrders(updatedOrders);
        setAddOrderModalVisible(false);
        setSelectedOrder({});
        setSelectedClientId("");
        updateForm.resetFields();
        message.success("Order updated successfully");
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to update order");
      setState("error");
    }
  }

  async function handleUpdateDeliveryStatus(val) {
    try {
      setState("loading");
      const res = await _fetch(
        `order/update-delivery-status/${updateDeliveryStatus._id}`,
        {
          method: "PATCH",
          body: val,
        }
      );
      if (res.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === updateDeliveryStatus._id) {
            return res.response;
          }
          return order;
        });
        setOrders(updatedOrders);
        setUpdateDeliveryStatus({});
        message.success("Delivery status updated successfully");
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to update delivery status");
      setState("error");
    }
  }

  async function handlePaymentStatus(val) {
    const payload = {
      paid: val.paid ? true : false
    }
    try {
      setState("loading");
      const res = await _fetch(
        `order/update-payment-status/${updatePaymentStatus._id}`,
        {
          method: "PATCH",
          body: payload,
        }
      );
      if (res.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === updatePaymentStatus._id) {
            return res.response;
          }
          return order;
        });
        setOrders(updatedOrders);
        setUpdatePaymentStatus({});
        message.success("Payment status updated successfully");
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
    }

  }

  async function handleAddLocationHistory(val) {
    try {
      setState("loading");
      const res = await _fetch(
        `order/add-location-history/${addLocationHistory._id}`,
        {
          method: "PATCH",
          body: {
            location: val
          }
        }
      );
      if (res.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === addLocationHistory._id) {
            return res.response;
          }
          return order;
        });
        setOrders(updatedOrders);
        setAddLocationHistory({});
        message.success("Location history added successfully");
        setState("success");
      } else {
        message.error(res.message);
        setState("error")
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to add location history");
      setState("error");
    }
  }

  async function handleUpdateStatus(val) {
    try {
      setState("loading");
      const res = await _fetch(`order/update-status/${updateStatus._id}`, {
        method: "PATCH",
        body: val,
      });
      if (res.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === updateStatus._id) {
            return res.response;
          }
          return order;
        });
        setOrders(updatedOrders);
        setUpdateStatus({});
        message.success("Order status updated successfully");
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to update order status");
      setState("error");
    }
  }

  useEffect(() => {
    init();
  }, [_id]);

  return (
    <div className="flex-1 w-full my-20">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Manage Client Orders
        </Typography.Title>
        {actions?.includes(order_actions.CREATE) && (
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            loading={state === "loading"}
            onClick={() => {
              setAddOrderModalVisible(true);
            }}
          >
            Create Order
          </Button>
        )}
      </div>
      <div className="mt-4">
        <Table
          columns={columns}
          dataSource={orders}
          loading={state === "loading"}
          rowKey="_id"
          pagination={{
            pageSize: 10,
          }}
          bordered
          scroll={{
            x: true,
          }}
        />
      </div>
      <Modal
        title="Update Order Status"
        open={updateStatus?._id}
        onCancel={() => setUpdateStatus({})}
        footer={null}
        destroyOnClose
      >
        <Form
          name="update-status"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={updateStatus}
          onFinish={handleUpdateStatus}
        >
          <Typography.Title level={4}>
            Update Status for Order: {updateStatus?.workflow_number}
          </Typography.Title>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "Please select a status!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.values(consts?.order_statuses).map((status) => (
                <Select.Option value={status} key={status}>
                  {status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full"
            >
              Update Status
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Add Location History"
        open={addLocationHistory?._id}
        onCancel={() => setAddLocationHistory({})}
        footer={null}
        destroyOnClose
        centered
        width={900}
      >
        <Row
          gutter={[24, 24]}
        >
          <Col
            xs={24}
            md={12}
          >
            <Form
              name="add-location-history"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={handleAddLocationHistory}
            >
              <Typography.Title level={4}>
                Add Location History for Order:{" "}
                {addLocationHistory?.workflow_number}
              </Typography.Title>
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Please input location!",
                  },
                ]}
              >
                <Input placeholder="Enter location" />
              </Form.Item>
              <Form.Item
                label="Date and Time"
                name="timestamp"
                rules={[
                  {
                    required: true,
                    message: "Please input date and time!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Select date and time"
                  showTime
                  format="DD MMM YYYY hh:mm:ss A"
                  className="w-full"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state === "loading"}
                  className="w-full"
                >
                  Add Location History
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            xs={24}
            md={12}
          >
            <div>
              <Typography.Title
                level={4}
              >
                Location History for Order:{" "}
                {addLocationHistory?.workflow_number}
              </Typography.Title>
              <ul>
                {addLocationHistory?.location_history?.map((location) => (
                  <li key={location?._id}>
                    <Typography.Text>
                      <strong>Location:</strong> {location.location}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      <strong>Date and Time:</strong>{" "}
                      {moment(location.timestamp).format("DD MMM YYYY hh:mm:ss A")}
                    </Typography.Text>
                    <hr />
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Update Delivery Status"
        open={updateDeliveryStatus?._id}
        onCancel={() => setUpdateDeliveryStatus({})}
        footer={null}
        destroyOnClose
      >
        <Form
          name="update-delivery-status"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={updateDeliveryStatus}
          onFinish={handleUpdateDeliveryStatus}
        >
          <Typography.Title level={4}>
            Update Delivery Status for Order:{" "}
            {updateDeliveryStatus?.workflow_number}
          </Typography.Title>
          <Typography.Text>
            Consignee Address: {updateDeliveryStatus?.consignee_address}
          </Typography.Text>
          <Form.Item name="delivered" valuePropName="checked" className="mt-4">
            <Checkbox>Delivered</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full"
            >
              Update Delivery Status
            </Button>
          </Form.Item>
        </Form>
      </Modal>



      <Modal
        title="Update payment Status"
        open={updatePaymentStatus?._id}
        onCancel={() => setUpdatePaymentStatus({})}
        footer={null}
        destroyOnClose
      >
        <Form
          name="update-payment-status"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={updatePaymentStatus}
          onFinish={handlePaymentStatus}
        >
          <Typography.Title level={4}>
            Order:{" "}
            {updatePaymentStatus?.workflow_number}
          </Typography.Title>

          <Form.Item name="paid" valuePropName="checked" className="mt-4">
            <Checkbox>Paid</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full"
            >
              Update payment Status
            </Button>
          </Form.Item>
        </Form>
      </Modal>



      <Modal
        title={addOrderModalVisible ? "Add Order" : "Edit Order"}
        open={addOrderModalVisible || selectedOrder?._id}
        onCancel={() => {
          setAddOrderModalVisible(false);
          setSelectedOrder({});
          setSelectedClientId("");
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          form={addOrderModalVisible ? addForm : updateForm}
          name={addOrderModalVisible ? "add-order" : "edit-order"}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            addOrderModalVisible
              ? {
                status: consts?.order_statuses.REQUESTED,
              }
              : {
                ...selectedOrder,
                estimated_time: moment(selectedOrder?.estimated_time).format(
                  "YYYY-MM-DD"
                ),
              }
          }
          onFinish={addOrderModalVisible ? createOrder : updateOrder}
        >
          <Form.Item
            label="Workflow Number"
            name="workflow_number"
            rules={[
              {
                required: true,
                message: "Please input workflow number!",
              },
            ]}
          >
            <Input placeholder="Enter workflow number" />
          </Form.Item>
          <Form.Item
            label="Offer Number"
            name="offer_number"
            rules={[
              {
                required: true,
                message: "Please input offer number!",
              },
            ]}
          >
            <Input placeholder="Enter offer number" />
          </Form.Item>
          <Form.Item
            label="Estimated Date"
            name="estimated_time"
            rules={[
              {
                required: true,
                message: "Please input estimated date!",
              },
            ]}
          >
            {selectedOrder?._id ? (
              <Input type="date" placeholder="Enter estimated date" />
            ) : (
              <Input
                type="date"
                placeholder="Enter estimated date"
                min={moment().add(1, "day").format("YYYY-MM-DD")}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Plant Address"
            name="plant"
            rules={[
              {
                required: true,
                message: "Please select a address!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Choose Plat address"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.values(consts?.company_address).map((address) => (
                <Select.Option value={address} key={address}>
                  {address}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Client"
            name="client"
            rules={[
              {
                required: true,
                message: "Please select a client!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a client"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(val) => {
                if (addOrderModalVisible) {
                  addForm.setFieldsValue({
                    enquiry: null,
                  });
                } else {
                  updateForm.setFieldsValue({
                    enquiry: null,
                  });
                }
                setSelectedClientId(val);
              }}
            >
              {clients.map((client) => (
                <Select.Option value={client._id} key={client._id}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Enquiry"
            name="enquiry"
            shouldUpdate={(prevValues, curValues) =>
              prevValues.client !== curValues.client
            }
          >
            <Select placeholder="Select an enquiry">
              {enquiries
                ?.filter((enquiry) => {
                  if (selectedClientId !== "") {
                    return enquiry.client?._id?.toString() === selectedClientId;
                  }
                  return true;
                })
                .map((enquiry) => (
                  <Select.Option value={enquiry._id} key={enquiry._id}>
                    {enquiry.status} -{" "}
                    {moment(enquiry.createdAt).format("DD-MMM-YYYY")}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "Please select a status!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={selectedOrder?._id}
            >
              {Object.values(consts?.order_statuses).map((status) => (
                <Select.Option value={status} key={status}>
                  {status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Consignee Address"
            name="consignee_address"
            rules={[
              {
                required: true,
                message: "Please input consignee address!",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter consignee address" rows={2} />
          </Form.Item>
          <Form.List
            label="Items"
            name="items"
            rules={[
              {
                validator: async (_, items) => {
                  if (!items || items.length < 1) {
                    message.error("Please add at least one item");
                    return Promise.reject("Please add at least one item");
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}
                    className="flex items-baseline gap-x-4"
                  >
                    <Form.Item
                      {...restField}
                      label="Item Name"
                      name={[name, "name"]}
                      rules={[{ required: true, message: "Missing item name" }]}
                      className="w-1/2"
                    >
                      <Input placeholder="Item name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Quantity"
                      name={[name, "quantity"]}
                      rules={[{ required: true, message: "Missing item quantity" }]}
                      className="w-1/4"
                    >
                      <InputNumber
                        placeholder="Quantity"
                        min={1}
                        suffix={consts?.units?.order?.quantity}
                        className="w-full"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Price"
                      name={[name, "price"]}
                      rules={[{ required: true, message: "Missing item price" }]}
                      className="w-1/4"
                    >
                      <InputNumber
                        placeholder="Price"
                        min={1}
                        suffix={consts?.units?.order?.price}
                        className="w-full"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Item
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item label="Delivered" name="delivered" valuePropName="checked">
            <Checkbox disabled={selectedOrder?._id}>Delivered</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full"
            >
              {addOrderModalVisible ? "Create Order" : "Update Order"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
