import { Button, Layout, message } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MenuOutlined, CloseOutlined, LogoutOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import logo from "../../../assets/images/logo.png";
import { getAuctionMenuRoutes } from "../../services/access-control";
import { useAuctionUser } from "../../hooks/auctionUser";
import useAuctionFetch from "../../hooks/fetch";
import {
  auctionUserLogout
} from "../../redux/auctionUserSlice";

const { Header: AntdHeader } = Layout;
export default function AuctionHeader() {

  const _fetch = useAuctionFetch();
  const user = useAuctionUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [routes, setRoutes] = useState(getAuctionMenuRoutes({}));
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const menuItems = routes.map((route, index) => ({
    key: `nav-menu-${index}`,
    title: route.title,
    label: (
      <Link to={route.path} className="text-inherit m-0">
        {route.title}
      </Link>
    ),
  }));

  const selectedKey = menuItems.find((item) => {
    return window.location.pathname === item.label.props.to;
  })?.key;

  async function handleLogout() {
    try {
      setLoading(true);
      const res = await _fetch("user/logout");
      if (res.success) {
        message.success(res.message);
        dispatch(auctionUserLogout());
        navigate("/");
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong while logging out");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setRoutes(getAuctionMenuRoutes(user));
  }, [user]);

  return (
    <AntdHeader className="w-full flex flex-col items-center justify-center bg-white h-auto">
      <div className="container w-full h-full flex items-stretch justify-between">
        <div className="flex items-center py-3 flex-1">
          <Link to="/" className="flex items-center ">
            <img src={logo} alt="logo" className="h-10 object-contain" />
          </Link>
        </div>
        <div className="hidden md:flex items-center justify-items-end gap-x-4 lg:gap-x-8">
          {menuItems.map((item) => (
            <div
              key={item.key}
              className="text-base h-full flex items-center transition-all"
              style={{
                borderBottom:
                  selectedKey === item.key ? "2px solid #1890ff" : "none",
                color: selectedKey === item.key ? "#1890ff" : "inherit",
                fontWeight: selectedKey === item.key ? "500" : "400",
              }}
            >
              {item.label}
            </div>
          ))}
          {user?._id && (
            <Button
              title="Logout"
              type="text"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              loading={loading}
              disabled={loading}
            />
          )}
        </div>
        <div className="flex items-center">
          <Button
            type="primary"
            className="md:hidden rounded"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
            icon={menuOpen ? <CloseOutlined /> : <MenuOutlined />}
            title="Toggle Menu"
          />
        </div>
      </div>
      {menuOpen && (
        <div className="container md:hidden w-full flex flex-col items-center gap-y-2 pb-4">
          {menuItems.map((item) => (
            <div
              key={item.key}
              className="text-base h-full flex items-center transition-all w-full rounded px-2 py-2"
              style={{
                color: selectedKey === item.key ? "#1890ff" : "inherit",
                fontWeight: selectedKey === item.key ? "500" : "400",
                backgroundColor:
                  selectedKey === item.key ? "#f0f0f0" : "inherit",
              }}
              onClick={() => {
                setMenuOpen(false);
              }}
            >
              {item.label}
            </div>
          ))}
          {user?._id && (
            <Button
              title="Logout"
              type="text"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              loading={loading}
              disabled={loading}
            >
              Logout
            </Button>
          )}
        </div>
      )}
    </AntdHeader>
  );
}
