import React from "react";
import cabel from '../../assets/images/cabel.jpg';
import Cabel_bundle from '../../assets/images/cabel_bundle.jpg';
import cables from '../../assets/images/26568.jpg';
import designer_cabel from '../../assets/images/cabel_bundle.jpg';
import cable_examples from '../../assets/images/26538.jpg';
import { GiWireCoil, GiRopeCoil, GiSkippingRope } from "react-icons/gi";
import { TbJumpRope } from "react-icons/tb";

// Array of beautiful colors
const colors = [
  "#E11D48", // Red
  "#F59E0B", // Orange
  "#10B981", // Green
  "#3B82F6", // Blue
  "#9333EA", // Purple
  "#EF4444", // Red
  "#F472B6", // Pink
  "#6EE7B7", // Teal
  "#8B5CF6", // Indigo
  "#6366F1", // Blue
];

// Array of icons to choose from
const icons = [
  <GiWireCoil size={20} color="white" />,
  <GiRopeCoil size={20} color="white" />,
  <GiSkippingRope size={20} color="white" />,
  <TbJumpRope size={20} color="white" />,
];

const products = [
  {
    image: designer_cabel,
    title: "High-Quality Cables",
    description: "Reliable, Durable, and Safe Products for Your Needs",
  },
  {
    image: cable_examples,
    title: "30+ years of experience",
  },
  {
    image: Cabel_bundle,
    title: "Leading Cable Manufacturer",
    description: "Modern Technology, Cutting-edge Design",
  },
  {
    image: cables,
    title: "One Stop Wire and Cable",
  },
  {
    image: cabel,
    title: "We Deliver to Your Expectation",
    description: "Based in Delhi, Works at Bhiwadi Dist-Alwar (Raj)",
  },
  // Add more products
];

const ProductSection = () => {
  // Function to get a random color from the array
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  // Function to get a random icon from the array
  const getRandomIcon = () => {
    const randomIndex = Math.floor(Math.random() * icons.length);
    return icons[randomIndex];
  };

  return (
    <section className="bg-gradient-to-r from-purple-600 via-indigo-500 to-blue-600pb-20 pt-10">
      <div className="text-center mb-10">
        {/* Title for the product section */}
        <h2 className="text-4xl font-semibold text-white">Our Products</h2>
        <p className="text-lg text-gray-300 mt-2">Delivering High-Quality and Reliable Cables for Every Need</p>
      </div>

      <div className="overflow-x-auto flex gap-8">
        <div className="flex gap-8 justify-start flex-nowrap items-center ml-20">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl h-[400px] shadow-lg w-80 cursor-pointer "
              style={{
                marginTop: index % 2 === 0 ? '80px' : '-80px',
              }}
            >
              <div className="w-full relative rounded">
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-full h-[400px] object-cover rounded-2xl"
                />
                {/* Hoverable Absolute Box */}
                <div className="absolute bottom-10 left-5 right-5 w-[90%] p-2 bg-white rounded-2xl text-black shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
                  <div className="flex items-center gap-2">
                    <div
                      className="rounded-full h-[35px] w-[35px] flex items-center justify-center"
                      style={{
                        backgroundColor: getRandomColor(),
                      }}
                    >
                      {getRandomIcon()}
                    </div>
                    <div className="flex flex-col items-start">
                      <span className="font-bold">{product.title}</span>
                      <span className="text-xs text-gray-500">₹250/Kg</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductSection;
