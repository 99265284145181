import React from "react";

import heroVideo from "../../assets/video/home.mp4";

const Video = () => {
  return (
    <section className="relative w-full h-screen overflow-hidden">
      <video
        className="w-full h-full object-cover absolute top-0 left-0"
        autoPlay
        muted
        loop
        playsInline // Ensure compatibility for mobile browsers
      >
        <source src={heroVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Optional: Add a fallback or overlay for better experience on mobile */}
      <div className="absolute top-0 left-0 w-full h-full bg-black/20"></div>
    </section>
  );
};

export default Video;
