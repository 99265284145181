import React, { useEffect, useState } from 'react';
import {
    Button,
    Table,
    Typography,
    message,
    Card,
    Modal,
    Form,
    Checkbox,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../../hooks/fetch';

export default function ViewPurchaseOrders() {
    const { id } = useParams();
    const _fetch = useFetch();
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [summary, setSummary] = useState({});
    const [loading, setLoading] = useState(true);
    const [updatePaymentStatus, setUpdatePaymentStatus] = useState({});

    const handlePaymentStatus = async (record) => {
        const payload = {
            paid: record.paid ? true : false
        }
        try {
            const res = await _fetch(`purchase-order/update-po-payment-status/${updatePaymentStatus._id}`, {
                method: 'PATCH',
                body: payload
            });
            if (res.success) {
                init();
                setUpdatePaymentStatus({});
                message.success('Payment status updated successfully');
            } else {
                message.error(res.message);
            }
        } catch (error) {
            console.error(error);
            message.error('Failed to update payment status');
        }
    }

    const init = async () => {
        try {
            const res = await _fetch(`purchase-order/vendor/${id}`);
            if (res.success) {
                const { PurchaseOrders, ...summary } = res.response;
                setPurchaseOrders(PurchaseOrders);
                setSummary(summary);
            } else {
                message.error('Failed to fetch purchase orders');
            }
        } catch (error) {
            message.error('An error occurred while fetching purchase orders');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        init();
    }, [id]);

    const columns = [
        {
            title: 'PO Number',
            dataIndex: 'po_number',
            key: 'po_number',
            render: (text, record) => (
                <Link
                    to={`/purchase-orders/${record._id}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {text}
                </Link>
            )
        },
        {
            title: 'Total Amount',
            dataIndex: 'items',
            key: 'totalAmount',
            render: (items) => items.reduce((acc, item) => acc + item.price, 0),
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            render: (paid) => (paid ? 'Yes' : 'No'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div>
                    {!record?.paid && (
                        <Button
                            title="Update Payment Status"
                            type="primary"
                            onClick={() => setUpdatePaymentStatus(record)}
                        >
                            Mark Paid
                        </Button>
                    )}
                </div>
            )
        }
    ];

    return (
        <div className='w-full mt-10'>
            <Typography.Title level={2}>Vendor Purchase Orders</Typography.Title>

            <Card title="Purchase Order Summary" bordered={false}>
                <p>Total Purchase Orders: <strong>{summary.totalOrders || 0}</strong></p>
                <p>Total Amount: <strong> ₹{summary.totalAmount || 0}</strong></p>
                <p>Paid Amount: <strong> ₹{summary.totalPaidAmount || 0}</strong></p>
                <p>Unpaid Amount: <strong> ₹{summary.totalUnpaidAmount || 0}</strong></p>
            </Card>

            <Table
                columns={columns}
                dataSource={purchaseOrders}
                loading={loading}
                rowKey="_id"
                pagination={{
                    pageSize: 10,
                }}
                bordered
                scroll={{
                    x: true,
                }}
            />

            <Modal
                title="Update Payment Status"
                open={updatePaymentStatus?._id}
                onCancel={() => setUpdatePaymentStatus({})}
                footer={null}
                destroyOnClose
            >
                <Form
                    name="update-payment-status"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={updatePaymentStatus}
                    onFinish={handlePaymentStatus}
                >
                    <Typography.Title level={4}>
                        PO: {updatePaymentStatus?.po_number}
                    </Typography.Title>

                    <Form.Item name="paid" valuePropName="checked" className="mt-4">
                        <Checkbox>Paid</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            className="w-full"
                        >
                            Update Payment Status
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
