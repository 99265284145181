import React from "react";
import Marquee from "react-fast-marquee";


const clients = [
  { logo: 'https://mir-s3-cdn-cf.behance.net/projects/404/5e0f9d194979731.Y3JvcCwyNzEzLDIxMjIsNzMsMTA3.png'},
  { logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLvsmM8WrP3J3d3Ho4Cveg_QiaHU1ydIhf6g&s',  },
  { logo: 'https://trendlyne-media-mumbai-new.s3.amazonaws.com/profilepicture/1108_profilepicture.jpg',},
  { logo: 'https://media-cdn.tripadvisor.com/media/photo-s/09/5c/58/c3/domino-s-pizza-newton.jpg' },
  { logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZG7z-gTqpfgXVDiVsHQxlL9ZmP7RkDgUw1A&s' },

];

const ClientsSection = () => {
  return (
    <section className="bg-gradient-to-r from-purple-600 via-indigo-500 to-blue-600 py-16">
      <div className="max-w-7xl  text-white">
        {/* Title */}
        <div className="px-20 text-center w-screen">
        <h2 className="text-4xl font-semibold mb-6">
          Our Clients
        </h2>
        <p className="text-lg mb-12 opacity-80">
          We're incredibly proud to collaborate with these outstanding, industry-leading brands.
        </p>
        </div>
        <div className="w-screen">
        <Marquee  speed={50} autoFill pauseOnHover  >
          {clients.map((client, index) => (
            <div key={index} className="flex-shrink-0 mx-4">
              <img
                src={client.logo}
                alt={client.name}
               
                className="w-32 h-32 object-cover "
              />
            </div>
          ))}
        </Marquee>
        </div>
    
      </div>
    </section>
  );
};

export default ClientsSection;
