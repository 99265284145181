import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

const { Content } = Layout;

export default function MainLayout({
  children,
  useContainer = true,
  usePadding = true,
}) {
  const location = useLocation();

  const marginTopClass = location.pathname === '/' ? 'mt-0' : 'mt-20';

  return (
    <Layout className="min-h-screen h-auto w-screen flex flex-col items-center justify-between">
      <Header />
      <Content
        className={`flex flex-col items-center justify-center grow ${useContainer ? 'container' : 'w-full'} ${usePadding ? 'py-4' : ''} ${marginTopClass}`}
      >
        {children}
      </Content>
      <Footer />
    </Layout>
  );
}
