import { useEffect, useState } from "react";
import { Button, Form, Input, App } from "antd";
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";

import useFetch from "../../../hooks/fetch";
import { login as loginAction } from "../../../redux/userSlice";
import { useUser } from "../../../hooks/user";
import { setConst } from "../../../redux/constSlice";
import logo from "../../../assets/images/logo.png";

export default function Login() {
  const { _id } = useUser();
  const _fetch = useFetch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = App.useApp();

  const [state, setState] = useState("success"); // ["success", "loading", "error"]

  async function submitForm(val) {
    try {
      setState("loading");
      let res = await _fetch("user/login", {
        method: "POST",
        body: val,
      });
      if (res.success) {
        message.success(res.message);
        dispatch(loginAction(res.response));
        res = await _fetch("const", {}, false);
        if (res.success) {
          dispatch(setConst(res.response));
        }
        setState("success");
        navigate("/");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong");
      setState("error");
    }
  }

  useEffect(() => {
    if (_id) navigate("/", { replace: true });
  }, [_id, navigate]);

  return (
    <div className="">
      <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-2xl">
      <div className="flex items-center justify-center w-full md:w-auto">
          <Link to="/" className="flex items-center justify-center my-5">
            <img src={logo} alt="Rashi Cables" className="h-12 object-contain" />
          </Link>
        </div>

        <Form
          name="login"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={submitForm}
          autoComplete="off"
        >
          {/* Email Field */}
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              placeholder="Email"
              className="border-gray-300 rounded-lg p-4 mb-6 text-lg shadow-lg transition duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:ring-2 hover:ring-indigo-400"
            />
          </Form.Item>

          {/* Password Field */}
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              className="border-gray-300 rounded-lg p-4 mb-6 text-lg shadow-lg transition duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:ring-2 hover:ring-indigo-400"
            />
          </Form.Item>

          {/* Login Button */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 bg-gradient-to-r from-purple-600 via-indigo-500 to-blue-600 text-white shadow-lg hover:shadow-xl"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
