import { useDispatch } from "react-redux";
import { logout } from "../redux/userSlice";

export default function useFetch() {
    const dispatch = useDispatch();

    async function _fetch(url, obj = {}, redirect = true) {
        try {
            const baseURL = process.env.REACT_APP_API_URL;
            const callURL = `${baseURL}/${url}`;
            let headers = {};
            let { method = "GET", body = undefined } = obj;

            if (body) {
                headers["Content-Type"] = "application/json";
            }

            const options = {
                headers,
                body: body ? JSON.stringify(body) : undefined,
                method,
                credentials: "include",
            };

            let res = await fetch(callURL, options);

            // Handle binary response (e.g., for file downloads)
            const contentType = res.headers.get("Content-Type");
            if (contentType && contentType.includes("application/pdf")) {
                return res;
            }

            // Default JSON parsing
            res = await res.json();
            if (redirect) {
                if (res.status === 401 || res.status === 403) {
                    dispatch(logout());
                    window.location.href = "/login";
                }
            }
            return res;
        } catch (err) {
            console.error(err);
            return { success: false, message: "Something went wrong" };
        }
    }

    return _fetch;
}
