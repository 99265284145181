import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/fetch";
import {
  Col,
  Row,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useConst } from "../../hooks/const";
import {
  OrderViewDetails,
  getOrderViewDetails,
} from "../../services/access-control";
import { useUser } from "../../hooks/user";

export default function OrderDetails() {
  const { id } = useParams();
  const _fetch = useFetch();
  const consts = useConst();
  const user = useUser();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [order, setOrder] = useState({});
  const orderItemDetails = getOrderViewDetails(user, consts);

  let orderItemCols = [];

  if (orderItemDetails?.includes(OrderViewDetails.NAME)) {
    orderItemCols.push({
      title: "Item",
      dataIndex: "name",
      key: "item",
    });
  }
  if (orderItemDetails?.includes(OrderViewDetails.QUANTITY)) {
    orderItemCols.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) => `${quantity} ${consts?.units?.order?.quantity}`,
    });
  }
  if (orderItemDetails?.includes(OrderViewDetails.PRICE)) {
    orderItemCols.push({
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `${price} ${consts?.units?.order?.price}`,
    });
  }

  async function init() {
    try {
      setState("loading");
      const res = await _fetch(`order/${id}`);
      if (res.success) {
        setOrder({
          ...res.response,
          total_price: res.response.items.reduce(
            (acc, item) => acc + item.price,
            0
          ),
        });
        setState("success");
      } else {
        setState("error");
        message.error(res.message);
      }
    } catch (err) {
      setState("error");
      message.error("Failed to fetch order details.");
    }
  }

  useEffect(() => {
    if (id) {
      init();
    }
  }, []);

  return (
    <div className="flex-1 w-full mt-20">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Order Details: {order.workflow_number}
        </Typography.Title>
        <Link to={`/orders?_id=${order._id}`} className="text-lg">
          <EditOutlined className="mr-2" />
          Edit
        </Link>
      </div>
      <div>
        <Typography.Title level={4}>Order Information</Typography.Title>
        <Row gutter={[16, 16]} className="my-4 md:my-8">
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Order Number:</b> {order.workflow_number}
            </Typography.Text>
          </Col>
          {
            orderItemDetails?.includes(OrderViewDetails.PRICE) && (
              <Col xs={24} sm={12} md={8}>
                <Typography.Text>
                  <b>Order Price:</b> {order.total_price}{" "}
                  {consts?.units?.order?.price}
                </Typography.Text>
              </Col>
            ) 
          }
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Order Number:</b> {order.workflow_number}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Order Date:</b> {moment(order.createdAt).format("DD MMM YYYY")}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Order Updated:</b>{" "}
              {moment(order.updatedAt).format("DD MMM YYYY")}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Order Status:</b> {order.status}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Consignee Address:</b> {order.consignee_address}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Estimated Date:</b>{" "}
              {moment(order.estimated_time).format("DD MMM YYYY")}
            </Typography.Text>
          </Col>
        </Row>
        <Table
          dataSource={order.items}
          columns={orderItemCols}
          loading={state === "loading"}
        />
      </div>
      <div>
        <Typography.Title level={4}>Client Information</Typography.Title>
        <Row gutter={[16, 16]} className="my-4 md:my-8">
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Client Name:</b>{" "}
              <Link
                to={`/clients?_id=${order.client?._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {order.client?.name}
              </Link>
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Client GST Number:</b> {order.client?.gst_number}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>GST Address:</b> {order.client?.gst_address}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Client Payment Terms:</b> {order.client?.payment_terms} days
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Phone Numbers:</b>{" "}
              {order.client?.phones?.map((phone, index) => (
                <Link key={index} to={`tel:${phone}`}>
                  {phone}{" "}
                </Link>
              ))}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Emails:</b>{" "}
              {order.client?.emails?.map((email, index) => (
                <Link key={index} to={`mailto:${email}`}>
                  {email}{" "}
                </Link>
              ))}
            </Typography.Text>
          </Col>
        </Row>
      </div>
      <div>
        <Typography.Title level={4}>
          Location History Information
        </Typography.Title>
        <Table
          dataSource={order.location_history}
          columns={[
            {
              title: "Location",
              dataIndex: "location",
              key: "location",
            },
            {
              title: "Date",
              dataIndex: "timestamp",
              key: "date",
              render: (date) => moment(date).format("DD MMM YYYY"),
            },
            {
              title: "Time",
              dataIndex: "timestamp",
              key: "time",
              render: (date) => moment(date).format("hh:mm A"),
            },
          ]}
          className="mt-4 md:mt-8"
          loading={state === "loading"}
        />
      </div>
    </div>
  );
}
