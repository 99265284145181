import React from 'react';
import { GiWireCoil, GiRopeCoil, GiSkippingRope } from "react-icons/gi";
import { GiStrong } from "react-icons/gi";
import { TbJumpRope } from "react-icons/tb";

// List of products with icons and titles
const products = [
  {
    icon: <GiStrong color='white' size={40} />,
    title: "High-Quality Cables",
  },
  {
    icon: <TbJumpRope color='white' size={40} />,
    title: "30+ years of experience",
  },
  {
    icon: <GiRopeCoil color='white' size={40} />,
    title: "Leading Cable Manufacturer",
  },
  {
    icon: <GiWireCoil color='white' size={40} />,
    title: "One Stop Wire and Cable",
  },
  {
    icon: <GiSkippingRope color='white' size={40} />,
    title: "We Deliver to Your Expectation",
    description: "Based in Delhi, Works at Bhiwadi Dist-Alwar (Raj)",
  },
];

const Product2 = () => {
  return (
    <div className='p-20'>
      {/* Title Section */}
      <div className="text-center mb-12">
        <h2 className="text-4xl font-semibold text-gray-800">Powering Connections, Empowering Futures</h2>
        <p className="text-lg text-gray-600 mt-2">Reliable, Durable, and High-Quality Cable Solutions</p>
      </div>

      {/* Product Grid */}
      <div className='grid grid-cols-2 lg:grid-cols-5 gap-4 md:gap-6 w-full'>
        {products.map((product, index) => (
          <div
            className='rounded-3xl shadow-[0px 0px 14px 0px rgba(205, 152, 255, 0.5)] px-4 py-4 md:p-12 lg:px-2 lg:py-6 xl:p-6 bg-white flex flex-col justify-center items-center gap-7 transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-2xl'
            key={`product-${index}`}
          >
            <div className='bg-gradient-to-r from-purple-600 via-indigo-500 to-blue-600 rounded-full h-20 w-20 flex items-center justify-center'>
              {product.icon}
            </div>
            <p className='text-[#111111CC] text-xs md:text-xl text-center'>
              {product.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product2;
