import Home from '../pages/Home';
import Clients from '../pages/Clients';
import Enquiries from '../pages/Enquiries';
import Orders from '../pages/Orders';
import PurchaseOrders from '../pages/PurchaseOrders';
import TrackOrder from '../pages/TrackOrder';
import Vendors from '../pages/Vendors';
import Login from '../pages/auth/Login';
import RegisterAsVendor from '../pages/auth/RegisterAsVendor';
import Users from '../pages/Users';
import Stocks from '../pages/Stocks';
import CreateFeedback from '../pages/CreateFeedback';
import ManageFeedback from '../pages/ManageFeedback';
import NotFound from '../pages/NotFound';
import OrderDetails from '../pages/Orders/details';
import PurchaseOrderDetails from '../pages/PurchaseOrders/details';
import Auctions from '../pages/Auction';
import AuctionDetails from '../pages/Auction/details';
import CreateEnquiry from '../pages/CreateEnquiry';

import MainLayout from '../components/layout/main';
import ProtectedLayout from '../components/layout/protected';
import DispatchPo from '../components/dispatch_po/Dispatch_po';
import ViewOrders from '../pages/Orders/ClientOrder';
import ViewPurchaseOrders from '../pages/PurchaseOrders/vendorPo';


const components = {
    home: {
        component: <MainLayout children={<Home />} useContainer={false} usePadding={false} />,
        path: '/',
        title: 'Home',
    },
    clients: {
        component: <ProtectedLayout children={<Clients />} />,
        path: '/clients',
        title: 'Clients',
    },
    enquiries: {
        component: <ProtectedLayout children={<Enquiries />} />,
        path: '/enquiries',
        title: 'Enquiries',
    },
    orders: {
        component: <ProtectedLayout children={<Orders />} />,
        path: '/orders',
        title: 'Client Orders',
    },
    clientOrders: {
        component: <ProtectedLayout children={<ViewOrders />} />,
        path: '/client/orders/:id',
        title: 'Client Orders',
    },
    vendorOrders: {
        component: <ProtectedLayout children={<ViewPurchaseOrders />} />,
        path: '/vendor/orders/:id',
        title: 'Vendor Orders',
    },
    orderDetails: {
        component: <ProtectedLayout children={<OrderDetails />} />,
        path: '/orders/:id',
        title: 'Order Details',
    },
    purchaseOrders: {
        component: <ProtectedLayout children={<PurchaseOrders />} />,
        path: '/purchase-orders',
        title: 'Purchase Orders',
    },
    purchaseOrderDetails: {
        component: <ProtectedLayout children={<PurchaseOrderDetails />} />,
        path: '/purchase-orders/:id',
        title: 'Purchase Order Details',
    },
    trackOrder: {
        component: <MainLayout children={<TrackOrder />} />,
        path: '/track-order',
        title: 'Track Order',
    },
    vendors: {
        component: <ProtectedLayout children={<Vendors />} />,
        path: '/vendors',
        title: 'Vendors',
    },
    stocks: {
        component: <ProtectedLayout children={<Stocks />} />,
        path: '/stocks',
        title: 'Stocks',
    },
    users: {
        component: <ProtectedLayout children={<Users />} />,
        path: '/users',
        title: 'Users',
    },
    login: {
        component: <MainLayout children={<Login />} />,
        path: '/login',
        title: 'Login',
    },
    registerAsVendor: {
        component: <MainLayout children={<RegisterAsVendor />} />,
        path: '/register-as-vendor',
        title: 'Register As Vendor',
    },
    feedback: {
        component: <MainLayout children={<CreateFeedback />} />,
        path: '/feedback',
        title: 'Feedback',
    },
    createEnquiry: {
        component: <MainLayout children={<CreateEnquiry />} />,
        path: '/create-enquiry',
        title: 'Create Enquiry',
    },
    manageFeedback: {
        component: <ProtectedLayout children={<ManageFeedback />} />,
        path: '/manage-feedback',
        title: 'Manage Feedback',
    },
    auctions: {
        component: <ProtectedLayout children={<Auctions />} />,
        path: '/auctions',
        title: 'Auctions',
    },
    auctionDetails: {
        component: <ProtectedLayout children={<AuctionDetails />} />,
        path: '/auctions/:id',
        title: 'Auction Details',
    },
    notFound: {
        component: <MainLayout children={<NotFound />} />,
        path: '*',
        title: 'Not Found',
    },
    dispatchPo:{
       component: <MainLayout children={<DispatchPo />} />,
        path: '/dispatch-po/:id',
        title: 'Dispatch PO',
    }
}

const publicRoutes = [
    components.home,
    components.login,
    components.registerAsVendor,
    components.createEnquiry,
    components.trackOrder,
    components.feedback,
    components.dispatchPo
];


export function getRoutes(user = {}, consts = {}){
    if(!user?.role || !consts?.user_roles) {
        return [...publicRoutes, components.notFound]
    } else if(user?.role === consts?.user_roles?.ADMIN){
        return [
            components.home,
            components.clients,
            components.vendors,
            components.enquiries,
            components.orders,
            components.orderDetails,
            components.purchaseOrders,
            components.purchaseOrderDetails,
            components.trackOrder,
            components.users,
            components.stocks,
            components.manageFeedback,
            components.auctions,
            components.auctionDetails,
            components.notFound,
            components.dispatchPo,
            components.clientOrders,
            components.vendorOrders
        ];
    } else if (user?.role === consts?.user_roles?.MARKETING_MANAGER){
        return [
            components.home,
            components.clients,
            components.orders,
            components.orderDetails,
            components.enquiries,
            components.notFound,
            components.dispatchPo,
            components.clientOrders
        ];
    } else if (user?.role === consts?.user_roles?.PURCHASE_MANAGER){
        return [
            components.home,
            components.vendors,
            components.purchaseOrders,
            components.vendorOrders,
            components.purchaseOrderDetails,
            components.notFound,
            components.dispatchPo
        ];
    } else if (user?.role === consts?.user_roles?.PRODUCTION_MANAGER){
        return [
            components.home,
            components.orders,
            components.notFound,
            components.dispatchPo
        ];
    } else if (user?.role === consts?.user_roles?.TRANSPORT_MANAGER){
        return [
            components.home,
            components.orders,
            components.notFound,
            components.dispatchPo
        ];
    } else if (user?.role === consts?.user_roles?.ACCOUNTANT){
        return [
            components.home,
            components.orders,
            components.orderDetails,
            components.purchaseOrders,
            components.purchaseOrderDetails,
            components.vendors,
            components.clients,
            components.enquiries,
            components.stocks,
            components.manageFeedback,
            components.auctions,
            components.auctionDetails,
            components.notFound,
            components.dispatchPo
        ];
    } else {
        return publicRoutes;
    }
}

export function getMenuRoutes(user = {}, consts = {}){
    if(!user?.role || !consts?.user_roles) {
        return publicRoutes.slice(0, publicRoutes.length - 2);
    } else if(user?.role === consts?.user_roles?.ADMIN){
        return [
            components.home,
            components.clients,
            components.vendors,
            components.enquiries,
            components.orders,
            components.purchaseOrders,
            components.users,
            components.stocks,
            components.manageFeedback,
            components.auctions
        ];
    } else if (user?.role === consts?.user_roles?.MARKETING_MANAGER){
        return [
            components.home,
            components.clients,
            components.orders,
            components.enquiries,
        ];
    } else if (user?.role === consts?.user_roles?.PURCHASE_MANAGER){
        return [
            components.home,
            components.vendors,
            components.purchaseOrders
        ];
    } else if (user?.role === consts?.user_roles?.PRODUCTION_MANAGER){
        return [
            components.home,
            components.orders
        ];
    } else if (user?.role === consts?.user_roles?.TRANSPORT_MANAGER){
        return [
            components.home,
            components.orders
        ];
    } else if (user?.role === consts?.user_roles?.ACCOUNTANT){
        return [
            components.home,
            components.orders,
            components.purchaseOrders,
            components.vendors,
            components.clients,
            components.enquiries,
            components.stocks,
            components.manageFeedback,
            components.auctions
        ];
    } else {
        return publicRoutes;
    }
}

export const order_actions = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    UPDATE_STATUS: 'UPDATE_STATUS',
    LOCATION_HISTORY: 'LOCATION_HISTORY',
    DELIVERY_STATUS: 'DELIVERY_STATUS',
}

export function getOrderActions(user = {}, consts = {}){
    if(!user?.role || !consts?.user_roles) {
        return [];
    } else if(user?.role === consts?.user_roles?.ADMIN){
        return [
            order_actions.CREATE,
            order_actions.UPDATE,
            order_actions.UPDATE_STATUS,
            order_actions.DELIVERY_STATUS,
            order_actions.LOCATION_HISTORY
        ];
    } else if (user?.role === consts?.user_roles?.MARKETING_MANAGER){
        return [
            order_actions.UPDATE,
            order_actions.CREATE,
        ];
    } else if (user?.role === consts?.user_roles?.PRODUCTION_MANAGER){
        return [
            order_actions.UPDATE_STATUS
        ];
    } else if (user?.role === consts?.user_roles?.TRANSPORT_MANAGER){
        return [
            order_actions.DELIVERY_STATUS,
            order_actions.LOCATION_HISTORY
        ];
    } else if (user?.role === consts?.user_roles?.ACCOUNTANT){
        return [
            order_actions.UPDATE
        ];
    } else return [];
}

export const OrderViewDetails = {
    NAME: 'NAME',
    QUANTITY: 'QUANTITY',
    PRICE: 'PRICE',
}

export function getOrderViewDetails(user = {}, consts = {}){
    if(!user?.role || !consts?.user_roles) {
        return [];
    } else if(user?.role === consts?.user_roles?.ADMIN){
        return [
            OrderViewDetails.NAME,
            OrderViewDetails.QUANTITY,
            OrderViewDetails.PRICE
        ];
    } else if (user?.role === consts?.user_roles?.MARKETING_MANAGER){
        return [
            OrderViewDetails.NAME,
            OrderViewDetails.QUANTITY,
        ];
    } else if (user?.role === consts?.user_roles?.ACCOUNTANT){
        return [
            OrderViewDetails.NAME,
            OrderViewDetails.QUANTITY,
            OrderViewDetails.PRICE
        ];
    } else return [];
}

export function canViewPODetails(user = {}, consts = {}){
    if(!user?.role || !consts?.user_roles) {
        return false;
    } else if(user?.role === consts?.user_roles?.ADMIN){
        return true;
    } else if (user?.role === consts?.user_roles?.PURCHASE_MANAGER){
        return true;
    } else if (user?.role === consts?.user_roles?.ACCOUNTANT){
        return true;
    } else return false;
}

export const POViewDetails = {
    NAME: 'NAME',
    QUANTITY: 'QUANTITY',
    PRICE: 'PRICE',
}

export function getPOViewDetails(user = {}, consts = {}){
    if(!user?.role || !consts?.user_roles) {
        return [];
    } else if(user?.role === consts?.user_roles?.ADMIN){
        return [
            POViewDetails.NAME,
            POViewDetails.QUANTITY,
            POViewDetails.PRICE
        ];
    } else if (user?.role === consts?.user_roles?.PURCHASE_MANAGER){
        return [
            POViewDetails.NAME,
            POViewDetails.QUANTITY,
        ];
    } else if (user?.role === consts?.user_roles?.ACCOUNTANT){
        return [
            POViewDetails.NAME,
            POViewDetails.QUANTITY,
            POViewDetails.PRICE
        ];
    } else return [];
}

export const HomeDetails = {
    CLIENT: 'CLIENT',
    VENDOR: 'VENDOR',
    ENQUIRY: 'ENQUIRY',
    ORDER: 'ORDER',
    PURCHASE_ORDER: 'PURCHASE_ORDER',
    STOCK: 'STOCK',
    AUCTION: 'AUCTION',
}

export function getHomeViewDetails(user = {}, consts = {}){
    if(!user?.role || !consts?.user_roles) {
        return [];
    } else if(user?.role === consts?.user_roles?.ADMIN){
        return [
            HomeDetails.CLIENT,
            HomeDetails.VENDOR,
            HomeDetails.ENQUIRY,
            HomeDetails.ORDER,
            HomeDetails.PURCHASE_ORDER,
            HomeDetails.STOCK,
            HomeDetails.AUCTION
        ];
    } else if (user?.role === consts?.user_roles?.MARKETING_MANAGER){
        return [
            HomeDetails.CLIENT,
            HomeDetails.ENQUIRY,
            HomeDetails.ORDER
        ];
    } else if (user?.role === consts?.user_roles?.PURCHASE_MANAGER){
        return [
            HomeDetails.VENDOR,
            HomeDetails.PURCHASE_ORDER
        ];
    } else if (user?.role === consts?.user_roles?.PRODUCTION_MANAGER){
        return [
            HomeDetails.ORDER
        ];
    } else if (user?.role === consts?.user_roles?.TRANSPORT_MANAGER){
        return [
            HomeDetails.ORDER
        ];
    } else if (user?.role === consts?.user_roles?.ACCOUNTANT){
        return [
            HomeDetails.ORDER,
            HomeDetails.PURCHASE_ORDER,
            HomeDetails.VENDOR,
            HomeDetails.CLIENT,
            HomeDetails.ENQUIRY,
            HomeDetails.STOCK,
            HomeDetails.AUCTION
        ];
    } else return [];
}